.head {
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #fafafa;
}

.car {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(0, 0);
}

@media only screen and ('max-width: 767px') {
  .car {
    width: 178px;
    height: 50px;
  }
}
