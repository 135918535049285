.root {
  display: flex;
  column-gap: 32px;
}

.wrapper {
  display: flex;
  column-gap: 4px;
}

@media only screen and ('max-width: 767px') {
  .wrapper {
    flex-direction: column;
    row-gap: 16px;
  }
}
