.root {
  column-gap: 16px;
  padding: 0 16px;
  margin-right: 0;
  width: 136px;
}

.flex {
  display: flex;
}
