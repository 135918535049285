.root {
  cursor: pointer;
  fill: #fafafa;

  &:hover {
    fill: #ff7319;
  }

  &:active {
    fill: #e45900;
  }
}

.active {
  fill: #ff8a3f;
}

.disabled {
  fill: #666666;
}
