.root {
  height: 100vh;
  user-select: none;
}

.none {
  display: none;
}

@media only screen and ('max-width: 767px') {
  .root {
    position: relative;
  }

  .wrapper {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translate(50%, -50%);
  }
}
