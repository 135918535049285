.root {
  display: flex;
  align-items: flex-end;
  position: absolute;
  left: 50%;
  bottom: 40px;
  transform: translate(-50%, 0);
}

.wrapper {
  display: flex;
}

.none {
  display: none;
}

@media only screen and ('max-width: 767px') {
  .root {
    flex-direction: column-reverse;
    bottom: 30px;
  }

  .wrapper {
    align-items: flex-end;
  }
}
