.root {
  margin-left: 32px;
}

.button {
  position: relative;
  height: 48px;
  width: 48px;
  border: none;
  background: #fafafa;
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    background: #ff7319;
  }

  &:active {
    background: #e45900;
  }
}

.activeButton {
  background: #ff8a3f;
}

.disabledButton {
  background: #666666;

  &:hover {
    background: #666666;
  }

  &:active {
    background: #666666;
  }
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #fafafa;
  cursor: pointer;
}

.disabledIcon {
  fill: #666666;
  cursor: not-allowed;
}

.none {
  display: none;
}

.block {
  display: block;
}

@media only screen and ('max-width: 767px') {
  .root {
    margin-left: 0;
    margin-top: 16px;
  }

  .button {
    height: 40px;
    width: 40px;
    margin-left: 0;
  }
}
