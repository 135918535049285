html,
body {
  padding: 0;
  margin: 0;
  font-family: Montserrat, sans-serif;
  overflow-y: hidden;
  overflow-x: hidden;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 0;
}

button,
input {
  overflow: visible;
}

input {
  border-radius: 0;
}

button,
select {
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;

  &:focus {
    outline: none !important;
  }
}

* {
  box-sizing: border-box;
}

button {
  user-select: none;
  cursor: pointer;
  color: inherit;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.preloader-screen {
  position: fixed;
  z-index: 99999;
  display: block;
  width: 100%;
  height: 100%;
  background: #1c1c1c;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section {
  position: relative;
  width: 100%;
  height: 50%;
}

.preloader-scooter-background {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -80px;
  display: block;
  overflow: hidden;
  width: 400px;
  height: 180px;
  margin-right: auto;
  margin-left: auto;
  z-index: 999;
}

.preloader-scooter-background {
  position: absolute;
  display: block;
  overflow: hidden;
  width: 30%;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
}

.l_img {
  display: block;
  width: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  margin-top: 50%;
}

.loading-text {
  display: none;
}

.l_bar {
  width: 0;
  margin: 1em auto;
  border: none;
  height: 4px;
  background: #fdc501;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-right: 15px !important;
}

@media only screen and ('max-width: 767px') {
  .preloader-scooter-background {
    width: 60%;
  }
}
