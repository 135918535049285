.root {
  position: relative;
  height: 48px;
  width: 48px;
  border: none;
  background: rgba(28, 28, 28, 0.4);
  border-radius: 12px;
  backdrop-filter: blur(4px);
  cursor: pointer;

  &:hover {
    .icon {
      fill: #ff7319;
    }
  }

  &:active {
    .icon {
      fill: #e45900;
    }
  }
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  fill: #fafafa;
}

.play {
  fill: #ff7319;
  width: 12px;
}

.pause {
  fill: #fafafa;
}

.block {
  display: block;
}

.none {
  display: none;
}

@media only screen and ('max-width: 767px') {
  .root {
    height: 40px;
    width: 40px;
  }
}
