.root {
  display: flex;
  column-gap: 32px;
}

.wrapper {
  display: flex;
  column-gap: 8px;
}

.none {
  display: none;
}

@media only screen and ('max-width: 767px') {
  .root {
    column-gap: 20px;
  }

  .wrapper {
    column-gap: 4px;
  }
}
