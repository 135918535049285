.root {
  column-gap: 24px;
  padding: 0 20px;
  margin-bottom: 8px;
  margin-right: 0;
  width: 352px;
}

.flex {
  display: flex;
}

.wrapper {
  position: relative;
  width: 24px;
  height: 24px;
}

.disc {
  width: 400px;
}

@media only screen and ('min-width: 821px') {
  .icon {
    cursor: pointer;

    &:hover {
      border-radius: 4px;
      outline: 1px solid #ff7319;
      outline-offset: 3px;
    }
  }

  .active {
    border-radius: 4px;
    outline: 1px solid #fafafa;
    outline-offset: 3px;
  }
}

@media only screen and ('max-width: 767px') {
  .root {
    width: 296px;
  }

  .wrapper {
    position: relative;
    width: 16px;
    height: 16px;
  }
}
