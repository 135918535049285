.root {
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile {
  transform: translate(-50%, -66%);
}
