/* Montserrat */

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(/fonts/Montserrat/Montserrat-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(/fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
