.footnote {
  visibility: hidden;
  border-radius: 12px;
  background: rgba(28, 28, 28, 0.4);
  backdrop-filter: blur(4px);
  opacity: 0;
  position: absolute;
  top: 25%;
  left: 70%;
  transform: translate(-70%, -25%);
}

.footnoteActive {
  visibility: visible;
  transition: all 2s ease;
  opacity: 1;
}

.text {
  padding: 20px 40px;
  width: 360px;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.03em;
  color: #fafafa;
}

.icon {
  position: absolute;
  right: 71px;
}

@media only screen and ('max-width: 767px') {
  .text {
    width: 220px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding: 9px 17px;
    border-radius: 5.075px;
  }
}
