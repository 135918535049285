.root {
  column-gap: 24px;
  padding: 0 20px;
  margin-bottom: 8px;
  margin-right: 0;
  width: 336px;
}

.flex {
  display: flex;
}

@media only screen and ('min-width: 821px') {
  .icon {
    cursor: pointer;

    &:hover {
      border-radius: 4px;
      outline: 1px solid #ff7319;
      outline-offset: 3px;
    }
  }

  .active {
    border-radius: 4px;
    outline: 1px solid #fafafa;
    outline-offset: 3px;
  }
}
