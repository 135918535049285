.root {
  margin-bottom: 8px;
  margin-right: 0;
  width: 381px;
}

.flex {
  display: flex;
}

@media only screen and ('max-width: 767px') {
  .root {
    width: 333px;
  }
}
