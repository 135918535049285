.root {
  display: flex;
  flex-direction: column;
}

.none {
  display: none;
}

.discsWrapper {
  display: flex;
  column-gap: 8px;
}

.flex {
  display: flex;
}

.selectedButton {
  position: absolute;
  bottom: 50px;
  left: 107px;
}

@media only screen and ('max-width: 767px') {
  .selectedButton {
    left: 0;
    bottom: 40px;
  }
}
