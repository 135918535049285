.root {
  display: none;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 328px;
  background: rgba(28, 28, 28, 0.4);
  backdrop-filter: blur(4px);
  border-radius: 12px;
  border: none;
  font-weight: 700;
  font-size: 14px;
  color: #fafafa;
  margin-right: 32px;
  white-space: nowrap;
}

@media only screen and ('max-width: 767px') {
  .root {
    height: 40px;
    width: 256px;
    margin-right: 0;
  }
}
