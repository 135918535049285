.root {
  cursor: pointer;
  color: #fafafa;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  padding: 16px;

  &:hover {
    color: #ff7319;
  }

  &:active {
    color: #e45900;
  }
}

.active {
  color: #ff8a3f;
}

.disabled {
  color: #666666;
}

@media only screen and ('max-width: 767px') {
  .root {
    padding: 12px 8px;
  }
}
